
import { Component, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import UpdateAccountForm from "@/components/forms/UpdateAccountForm.vue";
import UpdatePasswordForm from "@/components/forms/UpdatePasswordForm.vue";
import UpdateAddressForm from "@/components/forms/UpdateAddressForm.vue";
import countries from "@/helpers/countries.json";
@Component({
  components: {
    FormCard,
    UpdateAccountForm,
    UpdatePasswordForm,
    UpdateAddressForm
  }
})
export default class MyAccount extends Vue {
  private update_info = false;
  private update_password = false;
  private update_address = false;
  private ruta = "";

  private mounted() {
    if (this.$router.currentRoute.name == "myaccount")
      this.ruta = "Configuración";
  }

  private get nationality(): string {
    let nation = countries.find(
      country => country.value === this.$store.state.user.country
    );
    return nation!.label;
  }

  private success(event: string) {
    this.update_info = false;
    this.update_password = false;
    this.update_address = false;
    this.$q.notify({
      message: this.$t("notification." + event + "_change_success") as string,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private error(event: string, error: string) {
    this.update_info = false;
    this.update_password = false;
    this.update_address = false;
    var details = "";
    if (error == "password_not_match")
      details = ". La contraseña actual ingresada no coincide";

    this.$q.notify({
      message:
        (this.$t("notification." + event + "_change_error") as string) +
        details,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private back() {
    this.update_info = false;
    this.update_password = false;
    this.update_address = false;
  }

  private get rut(): string {
    if (this.$store.state.user.document_type == 1) {
      let id = this.$store.state.user.rut
        .replace(/[.-]/g, "")
        .replace(/\s/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      return id;
    } else {
      return this.$store.state.user.rut;
    }
  }
}
